var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"drugs"},[_c('el-carousel',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerArr.length && _vm.bannerShow),expression:"bannerArr.length && bannerShow"}],attrs:{"autoplay":false,"arrow":"never","height":"260px"}},[_c('div',{staticClass:"closeBanner",on:{"click":_vm.closeBanner}},[_c('img',{attrs:{"src":require("@image/drugsClose.png"),"alt":""}})]),_vm._l((_vm.bannerArr),function(item){return _c('el-carousel-item',{key:item.id},[_c('img',{attrs:{"src":item.images,"alt":""}})])})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isSecond),expression:"isSecond"}],staticClass:"recipe"},[_c('img',{attrs:{"src":require("@image/recipe.png"),"alt":""}}),_c('el-button',{attrs:{"round":""},on:{"click":function($event){return _vm.$router.push('/recipe')}}},[_vm._v("查看更多")])],1),_c('div',{staticClass:"main"},[_c('div',{staticClass:"left_tab"},[_c('el-tabs',{attrs:{"type":"card"},on:{"tab-click":_vm.tabClick},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":"西药","name":"first"}},[_c('el-menu',{on:{"open":function($event){return _vm.getCategoryId($event, 1)}}},[_vm._l((_vm.firstMenu),function(item,index){return [_c('el-submenu',{key:item.id,attrs:{"index":`${index}`}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-submenu__icon-arrow el-icon-caret-right"}),_c('span',[_vm._v(_vm._s(item.category_name))])]),_c('el-menu-item-group',_vm._l((item.children),function(it,indx){return _c('el-menu-item',{key:it.id,class:{
                      'is-active': index + '-' + indx == _vm.activeIndex1,
                    },attrs:{"index":`${index + '-' + indx}`},on:{"click":function($event){_vm.Highlight1(index + '-' + indx),
                        _vm.getDrugsList(1, index, indx, 1)}}},[_vm._v(_vm._s(it.category_name))])}),1)],2)]})],2)],1),_c('el-tab-pane',{attrs:{"label":"中药","name":"second"}},[_c('el-menu',{on:{"open":function($event){return _vm.getCategoryId($event, 2)}}},[_vm._l((_vm.secondMenu),function(item,index){return [_c('el-submenu',{key:item.id,attrs:{"index":`${index}`}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-submenu__icon-arrow el-icon-caret-right"}),_c('span',[_vm._v(_vm._s(item.category_name))])]),_c('el-menu-item-group',_vm._l((item.children),function(it,indx){return _c('el-menu-item',{key:it.id,class:{
                      'is-active': index + '-' + indx == _vm.activeIndex2,
                    },attrs:{"index":`${index + '-' + indx}`},on:{"click":function($event){_vm.Highlight2(index + '-' + indx),
                        _vm.getDrugsList(2, index, indx, 1)}}},[_vm._v(_vm._s(it.category_name))])}),1)],2)]})],2)],1)],1)],1),_c('div',{staticClass:"right_drugs"},[(_vm.AList.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.AListLoading),expression:"AListLoading"}]},_vm._l((_vm.AList),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('h4',{staticClass:"type"},[_vm._v(_vm._s(item.index_category))]),_c('ul',_vm._l((item.children),function(it){return _c('li',{key:it.id,on:{"click":function($event){return _vm.goList(
                  it.common_name,
                  it.drug_type1,
                  it.drug_type2,
                  it.drug_type3
                )}}},[_c('h4',[_vm._v(_vm._s(it.common_name)+" ("+_vm._s(it.sum)+")")]),_c('p',[_vm._v(_vm._s(it.group_info))])])}),0)])}),0):_c('div',{staticClass:"null-box"},[_c('img',{attrs:{"src":_vm.$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png',"alt":"","srcset":""}}),_c('div',{staticClass:"title"},[_vm._v("暂无数据")])]),_c('el-pagination',{attrs:{"hide-on-single-page":"","current-page":_vm.page,"page-size":_vm.page_size,"layout":"prev, pager, next, jumper","total":_vm.total},on:{"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }