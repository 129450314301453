<template>
  <div class="drugs" v-loading="loading">
    <!-- banner -->
    <el-carousel
      v-show="bannerArr.length && bannerShow"
      :autoplay="false"
      arrow="never"
      height="260px"
    >
      <div @click="closeBanner" class="closeBanner">
        <img src="@image/drugsClose.png" alt="" />
      </div>
      <el-carousel-item v-for="item in bannerArr" :key="item.id">
        <img :src="item.images" alt="" />
      </el-carousel-item>
    </el-carousel>

    <!-- 方剂banner -->
    <div class="recipe" v-show="isSecond">
      <img src="@image/recipe.png" alt="" />
      <el-button round @click="$router.push('/recipe')">查看更多</el-button>
    </div>

    <!-- 主要内容 -->
    <div class="main">
      <!-- 左侧tab栏 -->
      <div class="left_tab">
        <el-tabs v-model="activeName" type="card" @tab-click="tabClick">
          <!-- 西药 -->
          <el-tab-pane label="西药" name="first">
            <el-menu @open="getCategoryId($event, 1)">
              <template v-for="(item, index) in firstMenu">
                <el-submenu :key="item.id" :index="`${index}`">
                  <template slot="title">
                    <i class="el-submenu__icon-arrow el-icon-caret-right"></i>
                    <span>{{ item.category_name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      v-for="(it, indx) in item.children"
                      :key="it.id"
                      :index="`${index + '-' + indx}`"
                      :class="{
                        'is-active': index + '-' + indx == activeIndex1,
                      }"
                      @click="
                        Highlight1(index + '-' + indx),
                          getDrugsList(1, index, indx, 1)
                      "
                      >{{ it.category_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </template>
            </el-menu>
          </el-tab-pane>

          <!-- 中药 -->
          <el-tab-pane label="中药" name="second">
            <el-menu @open="getCategoryId($event, 2)">
              <template v-for="(item, index) in secondMenu">
                <el-submenu :key="item.id" :index="`${index}`">
                  <template slot="title">
                    <i class="el-submenu__icon-arrow el-icon-caret-right"></i>
                    <span>{{ item.category_name }}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item
                      v-for="(it, indx) in item.children"
                      :key="it.id"
                      :index="`${index + '-' + indx}`"
                      :class="{
                        'is-active': index + '-' + indx == activeIndex2,
                      }"
                      @click="
                        Highlight2(index + '-' + indx),
                          getDrugsList(2, index, indx, 1)
                      "
                      >{{ it.category_name }}</el-menu-item
                    >
                  </el-menu-item-group>
                </el-submenu>
              </template>
            </el-menu>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 右侧药品列表 -->
      <div class="right_drugs">
        <div v-if="AList.length" v-loading="AListLoading">
          <div class="item" v-for="(item, index) in AList" :key="index">
            <h4 class="type">{{ item.index_category }}</h4>
            <ul>
              <li
                @click="
                  goList(
                    it.common_name,
                    it.drug_type1,
                    it.drug_type2,
                    it.drug_type3
                  )
                "
                v-for="it in item.children"
                :key="it.id"
              >
                <h4>{{ it.common_name }} ({{ it.sum }})</h4>
                <p>{{ it.group_info }}</p>
              </li>
            </ul>
          </div>
        </div>

        <!-- 暂无数据 -->
        <div v-else class="null-box">
          <img
            :src="$http.baseURL + '/static/images/web/ecf28ef18a8e85ff.png'"
            alt=""
            srcset=""
          />
          <div class="title">暂无数据</div>
        </div>

        <!-- 分页 -->
        <el-pagination
          hide-on-single-page
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          :page-size="page_size"
          layout="prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      AListLoading: true,
      bannerArr: [], // 轮播图
      bannerShow: true,
      token: localStorage.getItem("token"),
      activeName: "first", // tab栏显示
      isSecond: false, // 判断是否显示方剂banner
      activeIndex1: "", // 西药二级药品高亮
      activeIndex2: "", // 中药药二级药品高亮
      drugList: [], // 药品分类列表
      firstMenuItem: [], // 药品分类列表
      secondMenuItem: [], // 药品分类列表
      drugsArray: [], // 药品列表
      AList: [], //字母列表
      firstMenu: [], // 西药
      secondMenu: [], // 中药
      firstIndex: 0, // 西药展开一级药品的index
      secondIndex: 0,
      first_parent_id: "", // 西药一级药品id
      second_parent_id: "",
      first_category_id: "", // 西药二级级药品id
      second_category_id: "",
      drugConfigList1: [], // 获取西药药品列表参数
      drugConfigList2: [],
      loading: true,
      page: 1,
      page_size: 50,
      total: 50,
      index1: "",
      index2: "",
      flag: false,
    };
  },

  watch: {
    // 监听展开西药一级药品的index
    firstIndex() {
      // 获取药品分类
      this.drugMenu2();
    },
    // 监听展开中药一级药品的index
    secondIndex() {
      this.drugMenu2();
    },
  },

  async created() {
    this.getBanner(8);
    // 获取药品id
    await this.getDrugId();
    // 获取药品一级分类
    await this.drugMenu1();
    // 默认获取西药所有
    await this.getAllDrugsList(1, 1);
    // 控制tab栏的显示
    if (!this.$route.query.type) return;
    this.activeName = this.$route.query.type;
    this.tabClick();
  },

  methods: {
    // 关闭banner
    closeBanner() {
      this.bannerShow = false;
    },

    // 轮播数据
    async getBanner(position) {
      let res = await this.$findApi.banner({
        position,
      });
      if (res.code == 200) {
        this.bannerArr = res.data;
      } else {
        this.$message.error(res.message);
      }
    },

    // tab栏切换(判断是否显示方剂banner)
    tabClick() {
      // 初始化
      this.loading = true;
      this.bannerShow = true;
      this.page = 1;
      this.AList = [];
      if (this.activeName == "first") {
        this.isSecond = false;
        if (this.firstMenuItem.length) {
          this.getDrugsList(
            1,
            this.firstMenuItem[0],
            this.firstMenuItem[1],
            this.page
          );
        } else {
          this.getAllDrugsList(1, 1);
        }
        this.$router.push({ path: "/drugs", query: { type: "first" } });
        this.getBanner(8);
      } else {
        this.isSecond = true;
        if (this.secondMenuItem.length) {
          this.getDrugsList(
            1,
            this.secondMenuItem[0],
            this.secondMenuItem[1],
            this.page
          );
        } else {
          this.getAllDrugsList(2, 1);
        }
        this.$router.push({ path: "/drugs", query: { type: "second" } });
        this.getBanner(7);
      }
    },

    // sub-menu展开的回调 获取一级药品id
    getCategoryId(index, type) {
      if (type == 1) {
        this.firstIndex = index;
        this.first_category_id = this.firstMenu[index].id;
      } else {
        this.secondIndex = index;
        this.second_category_id = this.secondMenu[index].id;
      }
    },

    // 获取药品id
    async getDrugId() {
      let res = await this.$findApi.drugMenu({
        parent_id: "-1",
      });
      if (res.code == 200) {
        this.drugList = res.data;
        this.first_parent_id = res.data[0].id;
        this.second_parent_id = res.data[1].id;
      } else {
        this.$message.error(res.message);
      }
    },

    // 获取药品一级分类
    async drugMenu1() {
      // 西药
      let res1 = await this.$findApi.drugMenu({
        parent_id: this.first_parent_id,
      });
      if (res1.code == 200) {
        this.firstMenu = res1.data;
        this.first_category_id = this.firstMenu[0].id;
      } else {
        this.$message.error(res1.message);
      }
      // 中药
      let res2 = await this.$findApi.drugMenu({
        parent_id: this.second_parent_id,
      });
      if (res2.code == 200) {
        this.secondMenu = res2.data;
        this.second_category_id = this.secondMenu[0].id;
      } else {
        this.$message.error(res2.message);
      }
    },

    // 获取药品二级分类
    async drugMenu2() {
      if (this.activeName == "first") {
        // 西药
        let res1 = await this.$findApi.drugMenu({
          parent_id: this.first_category_id,
        });
        if (res1.code == 200) {
          this.$set(this.firstMenu[this.firstIndex], "children", res1.data);
        } else {
          this.$message.error(res1.message);
        }
      } else {
        // 中药
        let res2 = await this.$findApi.drugMenu({
          parent_id: this.second_category_id,
        });
        if (res2.code == 200) {
          this.$set(this.secondMenu[this.secondIndex], "children", res2.data);
        } else {
          this.$message.error(res2.message);
        }
      }
    },

    // 默认获取右侧所有列表
    async getAllDrugsList(type, page) {
      let drugConfigList = [];
      if (type == 1) {
        drugConfigList.push(this.drugList[0]);
      } else {
        drugConfigList.push(this.drugList[1]);
      }
      this.categoryList(drugConfigList, page);
    },

    // 获取右侧药品列表
    async getDrugsList(type, index1, index2, page) {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      // 初始化
      this.index1 = index1;
      this.index2 = index2;
      this.flag = true;
      this.page = 1;
      let drugConfigList = [];
      this.drugConfigList1 = [];
      this.drugConfigList2 = [];
      // 西药
      if (type == 1) {
        this.drugConfigList1 = [];
        this.firstMenuItem = [index1, index2];
        this.drugConfigList1.push(
          this.drugList[0],
          this.firstMenu[index1],
          this.firstMenu[index1].children[index2]
        );
        drugConfigList = this.drugConfigList1;
      } else {
        this.drugConfigList2 = [];
        this.secondMenuItem = [index1, index2];
        // 中药
        this.drugConfigList2.push(
          this.drugList[1],
          this.secondMenu[index1],
          this.secondMenu[index1].children[index2]
        );
        drugConfigList = this.drugConfigList2;
      }
      this.categoryList(drugConfigList, page);
      this.page = page;
    },

    // 分页
    handleCurrentChange(page) {
      this.AListLoading = true;
      this.page = page;
      let type = this.activeName == "first" ? 1 : 2;
      if (this.flag) {
        this.getDrugsList(type, this.index1, this.index2, page);
      } else {
        this.getAllDrugsList(type, page);
      }
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },

    // 西药高亮
    async Highlight1(index) {
      this.activeIndex1 = index;
    },
    // 中药高亮
    async Highlight2(index) {
      this.activeIndex2 = index;
    },

    // 进入列表页(药品库分类信息详情查询)
    async goList(name, type1, type2, type3) {
      let drugConfigList = [];
      if (type1 == "西药") {
        drugConfigList = this.drugConfigList1;
      } else {
        drugConfigList = this.drugConfigList2;
      }
      localStorage.setItem("common_name", name);
      localStorage.setItem("drugConfigList", JSON.stringify(drugConfigList));
      this.$router.push(
        "/drugsList?type=" +
          this.activeName +
          "&type1=" +
          type1 +
          "&type2=" +
          type2 +
          "&type3=" +
          type3
      );
    },

    /******************************************************** 公共部分 ********************************************************/
    // 药品列表提取
    async categoryList(drugConfigList, page) {
      let res = await this.$findApi.categoryList({
        token: this.token,
        drugConfigList,
        page,
      });
      this.AListLoading = false;
      this.loading = false;
      if (res.code == 200) {
        this.drugsArray = res.data.list;
        this.page_size = res.data.pageSize;
        this.total = res.data.total;
        // 包装AList[{}]
        this.AList = [];
        this.drugsArray.forEach((item) => {
          let typeA = item.index_category;
          if (this.AList.indexOf(typeA) < 0) {
            this.AList.push(typeA);
          }
        });
        this.AList = this.AList.map((item) => {
          return (item = { index_category: item, children: [] });
        });
        this.drugsArray.forEach((item) => {
          this.AList.forEach((it) => {
            if (item.index_category == it.index_category) {
              it.children.push(item);
            }
          });
        });
      } else {
        this.$message.error(res.message);
      }
    },
    /******************************************************** 公共部分 ********************************************************/
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";

.drugs {
  width: 100%;
  margin: 0 auto;
  background: #f2f2f2;

  // 轮播图
  /deep/.el-carousel--horizontal {
    width: 1200px;
    margin: 0 auto;
    text-align: center;

    .el-carousel__container {
      img {
        height: 100%;
        object-fit: cover;
      }

      .closeBanner {
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 999;
        cursor: pointer;

        img {
          width: 25px;
        }
      }
    }
  }

  // 方剂
  .recipe {
    width: 1200px;
    margin: 0 auto;
    height: 120px;
    border-radius: 9px;
    overflow: hidden;
    margin-top: 20px;
    position: relative;

    img {
      width: 1200px;
      margin: 0 auto;
      height: 120px;
      object-fit: cover;
    }

    button {
      position: absolute;
      width: 110px;
      right: 490px;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      background: transparent;
      border-color: #fff;
      color: #fff;
      padding: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  // 主要内容
  .main {
    width: 1200px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    min-height: 300px;
    margin-bottom: 50px;

    // 左侧tab栏
    .left_tab {
      width: 240px;
      border-radius: 5px 5px 0 0;
      overflow: hidden;

      /deep/ {
        .el-tabs--card > .el-tabs__header .el-tabs__nav {
          width: 240px;
          border: none;
          background: #beeae4;
        }

        .el-tabs .el-tabs__header {
          margin-bottom: 0;
        }

        .el-tabs__item {
          width: 120px;
          height: 40px;
          line-height: 40px;
          padding: 0;
          text-align: center;
          color: #fff;
          border: none;
          border-bottom: 2px solid $--themeColor;
        }
        .el-tabs .el-tabs__item.is-active {
          transform: scale(1);
          background: $--themeColor;
          border-radius: 5px 5px 0 0;
        }

        .el-tabs__content {
          border: 1px solid #d9d9d9;
          border-top: none;
          min-height: 300px;
          background: #fff;
        }

        .el-menu {
          border-right: none;
          .el-submenu {
            border: none;

            .el-menu-item-group__title {
              display: none;
            }

            .el-submenu__title {
              height: 48px;
              line-height: 48px;
              border: none;
              padding-left: 30px !important;
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              display: block;

              &:hover {
                background: #fff;
              }
            }

            .el-menu-item {
              height: 40px;
              line-height: 40px;
              background: #fff;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              &:hover,
              &:active,
              &:visited {
                background: #fff;
              }
            }

            .el-submenu__icon-arrow {
              left: 10px;
              font-size: 18px;
              color: #333;
              width: 18px;
              height: 18px;
            }

            .el-icon-arrow-down {
              display: none;
            }
          }

          .el-submenu.is-opened {
            .el-submenu__title {
              color: $--themeColor;
            }
            .el-submenu__title .el-submenu__icon-arrow {
              transform: rotate(90deg);
              color: $--themeColor;
            }
          }
        }
      }
    }

    // 右侧药品
    .right_drugs {
      margin: 40px 0 0 20px;
      background: #ffffff;
      border: 1px solid #d9d9d9;
      padding: 10px 30px;
      flex: 1;

      .item {
        .type {
          border-bottom: 1px solid #d9d9d9;
          padding: 10px 0;
          font-size: 16px;
        }
        ul {
          li {
            border-bottom: 1px solid #d9d9d9;
            padding: 10px 0;
            cursor: pointer;
            h4 {
              margin-bottom: 5px;
              font-size: 16px;
            }
            p {
              font-size: 14px;
              color: #8e8e8e;
            }
          }
        }
      }

      .el-pagination {
        text-align: center;
        margin-top: 20px;
      }
    }
  }
}
</style>